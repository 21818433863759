import React from "react";
import cx from "classnames";
import Alert from "../Alert";
import LoadingBar from "../LoadingBar";
import { useHistory, useParams } from 'react-router-dom';
import * as Meta from "@elevio/kb-kit/lib/components/meta";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
const isIOS = () => {
    return (navigator.userAgent.match(/iPhone/i) != null);
};
function PageLayout({ children, className, header, footer }) {
    const history = useHistory();
    const params = useParams();
    return (<main className={cx("page bg-color page-text-color", className)}>
      <LoadingBar />
      {header}
      <Alert />
      <div className="body body-height">
        <div className="body-wrapper">{children}</div>
      </div>
      <div className={cx({ "submit-request-link": !isIOS() }, className, { 'submit-request-link-ios': isIOS() })}>
        <Meta.ExtraPageLink path="submitRequest">
          <button className={cx({ 'submit-request': !isIOS() }, { 'submit-request-ios': isIOS() })}>
              <FontAwesomeIcon icon={faEnvelope} size="lg" transform="left-4"/> <span>Make a Request</span>
            </button>
        </Meta.ExtraPageLink>
      </div>
      {footer}
    </main>);
}
export default PageLayout;
