import { v4 as uuidv4 } from 'uuid';
export const randomCodeGenerator = (length) => {
    const randomCode = uuidv4().substr(0, 5);
    return randomCode;
};
export const generateUniqueId = (prefix) => {
    const uniqueId = uuidv4().substr(0, 8);
    return `${prefix}_${uniqueId}`;
};
export const convertObjIntoUrlParams = (data) => {
    return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
};
