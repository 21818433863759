import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import _ from "lodash";
import * as Search from "@elevio/kb-kit/lib/components/search";
import { useLanguages } from "@elevio/kb-kit/lib/hooks";
import { beverlyBannerUrl } from '../pages/home';
import { Logo } from "./Logo";
import LoginLogout from "./LoginLogout";
import LanguagePicker from "./LanguagePicker";
import Validator from "../utils/Validators";
import { getSubmissions } from "../requests/user-submission";
import { getSSOConfig } from "../requests/get-sso-config";
import { getUserInfo } from "../requests/user-info";
import MetaTags from 'react-meta-tags';
import PhoneInput from 'react-phone-number-input/input';
import { getIDPToken } from "../requests/idp-access-token";
import { SID_KEY, USER_EMAIL_KEY, getDataFromLocalStorage, getTokenFromLocalStorage, removeDataFromLocalStorage, removeTokenFromLocalStorage, setDataInLocalStorage, setTokenInLocalStorage } from "../utils/local-storage";
import Loader from "./Loader";
import { getParameterByName } from "../utils/url-helper";
import { generateUniqueId, randomCodeGenerator } from "../utils/util";
import { setSIDIntoRedis } from "../requests/set-sid-into-redis";
import { ssoLogout } from "../requests/sso-logout";
import { BHIdpLogout } from "../requests/bh-idp-logout";
const IDP_TOKEN_KEY = 'idpAccessToken';
const DEFAULT_CRM_TOKEN = '34fva3a9xlb';
const SUBMISSION_OPTIONS = {
    MAGIC_LINK_AND_THIRD_PARTY_LOGIN: 'MAGIC_LINK_AND_THIRD_PARTY_LOGIN',
    MAGIC_LINK: 'MAGIC_LINK',
    THIRD_PARTY_LOGIN: 'THIRD_PARTY_LOGIN'
};
function Header({ children, className, hideSearch, breadcrumbs }) {
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
    const [displayRequestField, setDisplayRequestField] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [submissionOption, setSubmissionOption] = useState('');
    const [submissionLink, setSubmissionLink] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [displayRequestFieldType, setDisplayRequestFieldType] = useState('EMAIL');
    const [clientId, setClientId] = useState('');
    const [ssoBaseUrl, setSSOBaseUrl] = useState('');
    const [agencyCity, setAgencyCity] = useState('');
    const [agencyId, setAgencyId] = useState(null);
    const [accessToken, setAccessToken] = useState('');
    const [email, setEmail] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const redirectState = urlSearchParams.get('state');
    const BHState = redirectState === null || redirectState === void 0 ? void 0 : redirectState.split("-");
    const [anonymousId, setAnonymousId] = useState((BHState === null || BHState === void 0 ? void 0 : BHState[0]) || randomCodeGenerator(300));
    const [SID, setSID] = useState((BHState === null || BHState === void 0 ? void 0 : BHState[1]) || generateUniqueId('sid'));
    const requestSubmissionContainer = useRef(document.createElement('div'));
    const requestSubmissionContainerMobile = useRef(document.createElement('div'));
    const { supportedLanguages, selectedLanguage, setCurrentLanguageId, } = useLanguages();
    useEffect(() => {
        const existingToken = getTokenFromLocalStorage();
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (existingToken || urlSearchParams.get('code')) {
            setIsLoggingIn(true);
        }
        const script = document.createElement("script");
        if (isMobile()) {
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            document.body.appendChild(script);
            const script2 = document.createElement("script");
            script2.innerHTML = `function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'en',
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false
      },
        'google_translate_element_mobile');
       } `;
            document.body.appendChild(script2);
            script.async = true;
        }
        else {
            script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            const script3 = document.createElement("script");
            script3.innerHTML = ` function googleTranslateElementInit() {
      new google.translate.TranslateElement({
        pageLanguage: 'en',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false
      }, 'google_translate_element'); } `;
            document.body.appendChild(script3);
        }
        (async () => {
            await getSSOConfigDetails();
            if (window.location.pathname === '/en') {
                await redirectIfError();
            }
            await loginUser();
        })();
        document.body.appendChild(script);
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
    const redirectIfError = async () => {
        let redirectURL;
        if (getParameterByName('themeVersion')) {
            redirectURL = `${window.location.origin}/en?themeVersion=${getParameterByName('themeVersion')}&themeId=${getParameterByName('themeId')}`;
        }
        else {
            redirectURL = `${window.location.origin}/en`;
        }
        const redirectError = urlSearchParams.get('error');
        if (redirectError === 'login_required') {
            const result = await getSSOConfig();
            let url;
            url = `${result === null || result === void 0 ? void 0 : result.ssoBaseUrl}/authorize?client_id=${result === null || result === void 0 ? void 0 : result.clientAppId}&scope=openid+profile+email+phone+address&response_type=code&sid=${SID}&redirect_uri=${encodeURIComponent(redirectURL)}&state=${anonymousId}-${SID}`;
            window.location.href = url;
        }
    };
    const isTablet = () => {
        return navigator.userAgent.match(/iPad/i) != null;
    };
    const isMobile = () => {
        return (navigator.userAgent.match(/Android/i) != null ||
            navigator.userAgent.match(/iPhone/i) != null);
    };
    const isDesktop = () => window.screen.width > 1024;
    const isIE = () => {
        return false || !!document.documentMode;
    };
    const getSSOConfigDetails = async () => {
        const token = process.env.CRM_TOKEN || DEFAULT_CRM_TOKEN;
        setLoading(true);
        try {
            const result = await getSSOConfig();
            setSubmissionOption(result.submissionOption);
            setClientId(result.clientAppId);
            setSSOBaseUrl(result.ssoBaseUrl);
            setAgencyCity(result.agencyCity);
            setAgencyId(result.agencyId);
        }
        catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    const loginUser = async () => {
        setIsLoggingIn(true);
        const existingToken = getTokenFromLocalStorage();
        let token = existingToken;
        let redirectToListing = false;
        if (!existingToken) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const beverlyHillAuthCode = urlSearchParams.get('code');
            if (beverlyHillAuthCode && window.location.pathname === '/en') {
                token = await getBeverlyHillsToken(beverlyHillAuthCode);
                redirectToListing = true;
                setAccessToken(token);
                // window.location.reload();
            }
        }
        if (token) {
            const sid = getDataFromLocalStorage(SID_KEY);
            let contactInfo;
            contactInfo = await getUserInfoDetails(token);
            if (!sid) {
                setSIDIntoRedis(SID, contactInfo);
                setDataInLocalStorage(SID_KEY, SID);
            }
            setDataInLocalStorage(USER_EMAIL_KEY, contactInfo);
            const crmtoken = process.env.CRM_TOKEN || DEFAULT_CRM_TOKEN;
            if (contactInfo) {
                const { magicLink } = await getSubmissions(crmtoken, contactInfo, true);
                setSubmissionLink(magicLink);
                setIsLoggedIn(true);
                if (redirectToListing) {
                    window.location.href = magicLink;
                }
            }
        }
        setIsLoggingIn(false);
    };
    const getUserInfoDetails = async (accessToken) => {
        setLoading(true);
        try {
            const result = await getUserInfo(accessToken);
            setEmail(result.email);
            return result.email;
        }
        catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    const logout = async () => {
        setLoading(true);
        setIsLoggedIn(false);
        setAccessToken('');
        setSubmissionLink('');
        const SID = getDataFromLocalStorage(SID_KEY);
        await ssoLogout(SID, email, agencyId).then(async (response) => {
            const data = await BHIdpLogout(SID, clientId, ssoBaseUrl);
        });
        removeTokenFromLocalStorage();
        removeDataFromLocalStorage(SID_KEY);
        removeDataFromLocalStorage(USER_EMAIL_KEY);
        setEmail('');
        setLoading(false);
    };
    const handleClick = e => {
        if (isMobile() && requestSubmissionContainerMobile.current && !requestSubmissionContainerMobile.current.contains(e.target)) {
            setErrorMessage('');
            setDisplayRequestField(false);
            setResponseMessage('');
        }
        else if (!isMobile() && requestSubmissionContainer.current && !requestSubmissionContainer.current.contains(e.target)) {
            setErrorMessage('');
            setDisplayRequestField(false);
            setResponseMessage('');
        }
    };
    const toggleRequestField = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (displayRequestField) {
            setErrorMessage('');
        }
        setDisplayRequestField(!displayRequestField);
    };
    const onRequestSubmissionClickMobile = () => {
        setErrorMessage('');
        const validator = new Validator();
        if (contactInfo && (validator.isEmail(contactInfo) || validator.isPhone(contactInfo))) {
            const token = process.env.CRM_TOKEN || DEFAULT_CRM_TOKEN;
            setLoading(true);
            getSubmissions(token, contactInfo).then(result => {
                setContactInfo('');
                setResponseMessage(result.message);
                setDisplayRequestField(false);
            }).catch((error) => {
                console.log(error);
            }).finally(() => setLoading(false));
        }
        else {
            let errorMessage = `Incorrect ${isEmailSelected ? 'email' : 'mobile number'} format`;
            if (!contactInfo || contactInfo && !contactInfo.trim()) {
                errorMessage = 'The email or mobile number is required.';
            }
            setErrorMessage(errorMessage);
        }
    };
    const getBeverlyHillsToken = async (code) => {
        if (code && window.location.pathname === '/en') {
            try {
                const result = await getIDPToken(code);
                setTokenInLocalStorage(result.token);
                const themeVersion = getParameterByName('themeVersion');
                const themeId = getParameterByName('themeId');
                if (themeVersion) {
                    window.history.replaceState('', '', `/en?themeVersion=${themeVersion}&themeId=${themeId}`);
                }
                else {
                    window.history.replaceState('', '', `/en`);
                }
                return result.token;
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    const onRequestSubmissionClick = () => {
        setErrorMessage('');
        const validator = new Validator();
        let value = contactInfo;
        if (displayRequestFieldType === 'PHONE_NUMBER') {
            value = getActualPhoneNumberEntered(value);
        }
        if (value && (validator.isEmail(value) || validator.isPhone(value))) {
            const token = process.env.CRM_TOKEN || DEFAULT_CRM_TOKEN;
            setLoading(true);
            getSubmissions(token, value).then(result => {
                setContactInfo('');
                setResponseMessage(result.message);
                setDisplayRequestField(false);
            }).catch((error) => {
                console.log(error);
            }).finally(() => setLoading(false));
        }
        else {
            let errorMessage = `Incorrect ${isEmailSelected ? 'email' : 'mobile number'} format`;
            if (!contactInfo || contactInfo && !contactInfo.trim()) {
                errorMessage = 'The email or mobile number is required.';
            }
            setErrorMessage(errorMessage);
        }
    };
    const toggleRequestFieldType = (type) => {
        if (displayRequestFieldType !== type) {
            setContactInfo('');
        }
        setDisplayRequestFieldType(type);
        setErrorMessage('');
    };
    const onLoginButtonClick = () => {
        let redirectURL;
        if (getParameterByName('themeVersion')) {
            redirectURL = `${window.location.origin}/en?themeVersion=${getParameterByName('themeVersion')}&themeId=${getParameterByName('themeId')}`;
        }
        else {
            redirectURL = `${window.location.origin}/en`;
        }
        let url;
        url = `${ssoBaseUrl}/authorize?client_id=${clientId}&scope=openid+profile+email+phone+address&response_type=code&sid=${SID}&redirect_uri=${encodeURIComponent(redirectURL)}&state=${anonymousId}-${SID}&prompt=none`;
        console.log(url);
        window.location.href = url;
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onRequestSubmissionClick();
        }
    };
    const handleKeyDownMobile = (event) => {
        if (event.key === 'Enter') {
            onRequestSubmissionClickMobile();
        }
    };
    const getActualPhoneNumberEntered = (value) => {
        const charsToRemove = ['(', ')', ' ', '-'];
        const pattern = new RegExp(`[${charsToRemove.join('')}]`, 'g');
        const phone = value.replace(pattern, '');
        return phone;
    };
    const isEmailSelected = displayRequestFieldType === 'EMAIL';
    const isPhoneNumberSelected = displayRequestFieldType === 'PHONE_NUMBER';
    return (<div className="wrapper">
      {isLoggingIn &&
            <Loader className="logging-in" text={`Logging in...`}></Loader>}
    <MetaTags>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"/>
    </MetaTags>

    <header className={cx("header", "header-bg-color", "header-text-color", className)} data-testid="header">
      <div className="header-wrapper">
        <nav className="nav" aria-label="navigation">
          <div className="nav-wrapper">
            <div className="nav-details">

              <Logo />
            </div>
           
            <div className="nav-desktop-menu">
              <div className="request-submission-container" ref={requestSubmissionContainer}>
                {!isLoggedIn && <a className="nav-desktop-menu-link" href="#" onMouseDown={e => e.stopPropagation()} onClick={toggleRequestField}>
                  <div className="request-link">
                    <svg className="request-link-logo" height='100px' width='100px' fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><path d="M4.3,68.6l18.3,2.9V28.6L4.3,31.4V68.6z M8.7,35.2l9.6-1.5v32.7l-9.6-1.5V35.2z M77.8,28.6v42.9l17.9-2.9   V31.4L77.8,28.6z M91.3,64.8l-9.2,1.5V33.7l9.2,1.5V64.8z M26.4,83.5h47.3V16.5H26.4V83.5z M30.7,20.9h38.6v58.2H30.7V20.9z"></path></g></svg>
                    <span className="request-link-text">
                      <div>View Your Submissions</div>
                    </span>
                  </div>
                </a>}
                {isLoggedIn && <a className="nav-desktop-menu-link" href={submissionLink}>
                  <div className="request-link">
                    <svg className="request-link-logo" height='100px' width='100px' fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><path d="M4.3,68.6l18.3,2.9V28.6L4.3,31.4V68.6z M8.7,35.2l9.6-1.5v32.7l-9.6-1.5V35.2z M77.8,28.6v42.9l17.9-2.9   V31.4L77.8,28.6z M91.3,64.8l-9.2,1.5V33.7l9.2,1.5V64.8z M26.4,83.5h47.3V16.5H26.4V83.5z M30.7,20.9h38.6v58.2H30.7V20.9z"></path></g></svg>
                    <span className="request-link-text">
                      <div>View Your Submissions</div>
                    </span>
                  </div>
                </a>}
                {displayRequestField && !isLoggedIn && (<div className="request-field-dropdown">
                    <div className="request-field-dropdown-header">
                    </div>
                    <div className="request-field-dropdown-body">
                      <h4 className="request-field-dropdown-title">VIEW YOUR SUBMISSIONS</h4>
                      {[SUBMISSION_OPTIONS.MAGIC_LINK, SUBMISSION_OPTIONS.MAGIC_LINK_AND_THIRD_PARTY_LOGIN].includes(submissionOption) && (<div>
                          <div className="request-field-dropdown-text-wrapper">
                            <p className="request-field-dropdown-text">
                              If you don't have an account or submitted anonymously,
                              we'll email/text you a link to view your submissions
                            </p>
                          </div>
                          <p className="request-field-dropdown-text">
                              View your submission(s) by entering your associated email or phone number:
                            </p>
                            <div className="request-input-type-toggle-section">
                              <button className={`request-input-type-toggle-button ${isEmailSelected && 'selected'}`} disabled={loading} onClick={() => toggleRequestFieldType('EMAIL')}>
                                Email
                              </button>
                              <button className={`request-input-type-toggle-button ${isPhoneNumberSelected && 'selected'}`} disabled={loading} onClick={() => toggleRequestFieldType('PHONE_NUMBER')}>
                                Phone Number
                              </button>
                            </div>
                            {isEmailSelected && (<div className="request-input">
                                  <input placeholder="Enter your email address" onKeyDown={handleKeyDown} value={contactInfo} onChange={evt => setContactInfo(evt.target.value)}/>
                                  {errorMessage && (<div id="error-message">{errorMessage}</div>)}
                                </div>)}

                            {isPhoneNumberSelected && (<div className="request-input">
                                <PhoneInput placeholder={'(xxx) xxx-xxxx'} defaultCountry="US" onKeyDown={handleKeyDown} onChange={() => { }} onInput={(e) => setContactInfo(e.target.value)}/>
                                {errorMessage && (<div id="error-message">{errorMessage}</div>)}
                              </div>)}
                            <hr className="request-field-hr"></hr>
                            <div className="request-action">
                              <button disabled={loading || !contactInfo} onClick={onRequestSubmissionClick}>Submit</button>
                            </div>
                        </div>)}
                      {submissionOption === SUBMISSION_OPTIONS.MAGIC_LINK_AND_THIRD_PARTY_LOGIN && (<div>
                          <hr className="request-field-dropdown-divider"></hr>
                          <div className="request-field-dropdown-text-wrapper"><p>OR</p></div>
                        </div>)}
                      {[SUBMISSION_OPTIONS.THIRD_PARTY_LOGIN, SUBMISSION_OPTIONS.MAGIC_LINK_AND_THIRD_PARTY_LOGIN].includes(submissionOption) && (<div className="request-input-login-wrapper">
                          <div className="request-action">
                            <button disabled={loading} onClick={onLoginButtonClick}>
                              Click here to login with your {_.startCase(agencyCity)} Account
                            </button>
                          </div>
                        </div>)}
                    </div>
                  </div>)}
                {responseMessage && (<div className="request-field-dropdown response-message-container">
                    <div className="response-message">
                      <span onClick={() => setResponseMessage('')}>x</span>
                      <div>{responseMessage}</div>
                    </div>
                  </div>)}
              </div>
              {isLoggedIn && <div className="user-info-container">
                <span className="nav-desktop-menu-link">
                  <div className="request-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    <span className="user-email-text">
                      <div>{email}</div>
                    </span>
                  </div>
                </span>
                <a className="nav-desktop-menu-link" href="#" onMouseDown={e => e.stopPropagation()} onClick={logout}>
                  <div className="user-logout-link">
                    <span className="user-logout-text">
                      <div>Logout</div>
                    </span>
                  </div>
                </a>
                
                </div>}
              <div id="google_translate_element"></div>
              <LoginLogout className="nav-desktop-menu-link"/>
              {supportedLanguages.length > 1 && (<LanguagePicker selectedLanguageId={selectedLanguage.id} setCurrentLanguageId={setCurrentLanguageId} supportedLanguages={supportedLanguages}/>)}
            </div>

            <div className="mobile-menu">
            <div className="mobile-menu-wrapper">
              {/* <a className="mobile-menu-link" href="#">
        <Trans i18nKey="external-link">Our Site</Trans>
      </a> */}
              <div className="request-submission-container" ref={requestSubmissionContainerMobile}>
                {!isLoggedIn && <a className="nav-desktop-menu-link mobile-menu-link" href="#" onMouseDown={e => e.stopPropagation()} onClick={toggleRequestField}>
                  <div className="request-link">
                    <svg className="request-link-logo" height='100px' width='100px' fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><path d="M4.3,68.6l18.3,2.9V28.6L4.3,31.4V68.6z M8.7,35.2l9.6-1.5v32.7l-9.6-1.5V35.2z M77.8,28.6v42.9l17.9-2.9   V31.4L77.8,28.6z M91.3,64.8l-9.2,1.5V33.7l9.2,1.5V64.8z M26.4,83.5h47.3V16.5H26.4V83.5z M30.7,20.9h38.6v58.2H30.7V20.9z"></path></g></svg>
                    <span className="request-link-text">
                      <div>View Your Submissions</div>
                    </span>
                  </div>
                </a>}
                {isLoggedIn && <a className="nav-desktop-menu-link mobile-menu-link" href={submissionLink}>
                  <div className="request-link">
                    <svg className="request-link-logo" height='100px' width='100px' fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><g><path d="M4.3,68.6l18.3,2.9V28.6L4.3,31.4V68.6z M8.7,35.2l9.6-1.5v32.7l-9.6-1.5V35.2z M77.8,28.6v42.9l17.9-2.9   V31.4L77.8,28.6z M91.3,64.8l-9.2,1.5V33.7l9.2,1.5V64.8z M26.4,83.5h47.3V16.5H26.4V83.5z M30.7,20.9h38.6v58.2H30.7V20.9z"></path></g></svg>
                    <span className="request-link-text">
                      <div>View Your Submissions</div>
                    </span>
                  </div>
                </a>}
                {displayRequestField && !isLoggedIn && (<div className="request-field-dropdown">
                    <div className="request-field-dropdown-header">
                    </div>
                    <div className="request-field-dropdown-body">
                      <h4 className="request-field-dropdown-title">VIEW YOUR SUBMISSIONS</h4>
                      {[SUBMISSION_OPTIONS.MAGIC_LINK, SUBMISSION_OPTIONS.MAGIC_LINK_AND_THIRD_PARTY_LOGIN].includes(submissionOption) && (<div>
                          <div className="request-field-dropdown-text-wrapper">
                            <p className="request-field-dropdown-text">
                              If you don't have an account or submitted anonymously,
                              we'll email/text you a link to view your submissions
                            </p>
                          </div>
                          <div className="request-input-type-toggle-section">
                                <button className={`request-input-type-toggle-button ${isEmailSelected && 'selected'}`} disabled={loading} onClick={() => toggleRequestFieldType('EMAIL')}>
                                  Email
                                </button>
                                <button className={`request-input-type-toggle-button ${isPhoneNumberSelected && 'selected'}`} disabled={loading} onClick={() => toggleRequestFieldType('PHONE_NUMBER')}>
                                Phone Number
                              </button>
                            </div>
                            {isEmailSelected && (<div className="request-input">
                                <input placeholder="Enter your email address" onKeyDown={handleKeyDown} value={contactInfo} onChange={evt => setContactInfo(evt.target.value)}/>
                                {errorMessage && (<div id="error-message">{errorMessage}</div>)}
                              </div>)}
                            {isPhoneNumberSelected && (<div className="request-input">
                                <PhoneInput placeholder={'(xxx) xxx-xxxx'} defaultCountry="US" onKeyDown={handleKeyDown} onChange={() => { }} onInput={(e) => setContactInfo(e.target.value)}/>
                                {errorMessage && (<div id="error-message">{errorMessage}</div>)}
                              </div>)}
                            <hr className="request-field-hr"></hr>
                            <div className="request-action">
                              <button disabled={loading || !contactInfo} onClick={onRequestSubmissionClickMobile}>Submit</button>
                            </div>
                        </div>)}
                      {submissionOption === SUBMISSION_OPTIONS.MAGIC_LINK_AND_THIRD_PARTY_LOGIN && (<div>
                          <hr className="request-field-dropdown-divider"></hr>
                          <div className="request-field-dropdown-text-wrapper"><p>OR</p></div>
                        </div>)}
                      {[SUBMISSION_OPTIONS.THIRD_PARTY_LOGIN, SUBMISSION_OPTIONS.MAGIC_LINK_AND_THIRD_PARTY_LOGIN].includes(submissionOption) && (<div className="request-input-login-wrapper">
                          <div className="request-action">
                            <button disabled={loading} onClick={onLoginButtonClick}>
                              Click here to login with your {_.startCase(agencyCity)} Account
                            </button>
                          </div>
                        </div>)}
                    </div>
                  </div>)}
                {responseMessage && (<div className="request-field-dropdown response-message-container">
                    <div className="response-message">
                      <span onClick={() => setResponseMessage('')}>x</span>
                      <div>{responseMessage}</div>
                    </div>
                  </div>)}
                <div className="google-translate-button">
                  <div id="google_translate_element_mobile"></div>
                </div>
              </div>
              <LoginLogout className="mobile-menu-link"/>
              {supportedLanguages.length > 1 && (<LanguagePicker selectedLanguageId={selectedLanguage.id} setCurrentLanguageId={setCurrentLanguageId} supportedLanguages={supportedLanguages}/>)}
            </div>
          </div>

          </div>
        </nav>

          



        {displaySearchBar && !hideSearch && (<div className="mobile-search">
            <div className="mobile-search-wrapper">
              <Search.Input className="mobile-search-input"/>
            </div>
          </div>)}

        {children && (<div className="hero" style={{ backgroundImage: `url(${beverlyBannerUrl})` }}>
            <div className="hero-wrapper">{children}</div>
          </div>)}
        {breadcrumbs}
      </div>
    </header>
  </div>);
}
export default Header;
