const BASE_URL = process.env.COMCATE_API_URL;
const CRM_TOKEN = process.env.CRM_TOKEN;
export const getIDPToken = (authCode) => {
    const queryParams = new URLSearchParams({ token: process.env.CRM_TOKEN });
    return fetch(`${BASE_URL}/crm/${CRM_TOKEN}/sso/idp-token?code=${authCode}&${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        }
        else {
            throw Error(response.statusText);
        }
    });
};
