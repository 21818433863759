const IDP_TOKEN_KEY = 'idpAccessToken';
export const SID_KEY = 'sid';
export const USER_EMAIL_KEY = 'userEmail';
export const setTokenInLocalStorage = (token) => {
    // create token value for local storage with expiry time
    const data = {
        value: token,
        ttl: Date.now() + 86400 * 1000,
    };
    // set value in local storage
    localStorage.setItem(IDP_TOKEN_KEY, JSON.stringify(data));
};
export const getTokenFromLocalStorage = () => {
    const data = localStorage.getItem(IDP_TOKEN_KEY);
    if (!data) {
        // if no value exists associated with the key, return null
        return null;
    }
    const item = JSON.parse(data);
    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
        localStorage.removeItem(IDP_TOKEN_KEY);
        return null;
    }
    // return data if not expired
    return item.value;
};
export const removeTokenFromLocalStorage = () => {
    localStorage.removeItem(IDP_TOKEN_KEY);
};
export const setDataInLocalStorage = (key, value) => {
    // create value for local storage with expiry time
    const data = {
        value: value,
        ttl: Date.now() + 86400 * 1000,
    };
    // set value in local storage
    localStorage.setItem(key, JSON.stringify(data));
};
export const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    if (!data) {
        // if no value exists associated with the key, return null
        return null;
    }
    const item = JSON.parse(data);
    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
        localStorage.removeItem(key);
        return null;
    }
    // return data if not expired
    return item.value;
};
export const removeDataFromLocalStorage = (key) => {
    localStorage.removeItem(key);
};
