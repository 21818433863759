import { SID_KEY, USER_EMAIL_KEY, removeDataFromLocalStorage, removeTokenFromLocalStorage } from "../utils/local-storage";
const BASE_URL = process.env.COMCATE_API_URL;
const CRM_TOKEN = process.env.CRM_TOKEN;
export const getUserInfo = async (token) => {
    const queryParams = new URLSearchParams({ token: CRM_TOKEN });
    try {
        const response = await fetch(`${BASE_URL}/crm/${CRM_TOKEN}/sso/user-info?${queryParams}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({ token: token })
        });
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        }
        else {
            if ((response === null || response === void 0 ? void 0 : response.status) === 422) {
                removeTokenFromLocalStorage();
                removeDataFromLocalStorage(SID_KEY);
                removeDataFromLocalStorage(USER_EMAIL_KEY);
            }
        }
    }
    catch (error) {
        console.log('error,', error);
    }
};
