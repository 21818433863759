import * as React from "react";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { useTranslation, useCategories } from "@elevio/kb-kit/lib/hooks";
import * as Search from "@elevio/kb-kit/lib/components/search";
import * as Categories from "@elevio/kb-kit/lib/components/categories";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { MainSection, GridContainer, GridItem, } from "../components/layouts/Main";
import ArticleList from "../components/ArticleList";
import Breadcrumb from "../components/PageBreadcrumbs";
export const beverlyLogoUrl = `${window.assetsLocation}/images/beverly-logo-shield.svg`;
export const beverlyBannerUrl = `${window.assetsLocation}/images/beverly-banner.jpg`;
export const viewReportIconUrl = `${window.assetsLocation}/images/carousel.png`;
export const accessBeverlyLogoUrl = `${window.assetsLocation}/images/beverly-hills-logo-center.png`;
export const getHeader = () => {
    const { t } = useTranslation();
    return (<Header hideSearch breadcrumbs={<div className="breadcrumbs-header-wrapper">
          <Breadcrumb className="breadcrumbs-header"/>
        </div>}>
      <label htmlFor="search-input">
        <h2 className="header-text">
          <Trans i18nKey="home.welcome">
            How can we help?
          </Trans>
        </h2>
      </label>
      <Search.Input placeholder={t("search.placeholder", "Please Begin Typing Keywords...")} data-testid="search-input" className="hero-search search-bar" id="search-input"/>
    </Header>);
};
const HomePage = () => {
    const { categories } = useCategories();
    return (<PageLayout header={getHeader()} footer={<Footer />}>
      <MainSection className="page-text-color">
        <div className="beverly-logo-wrapper">
          <img className="beverly-logo" src={`${accessBeverlyLogoUrl}`} alt="Beverly"/>
        </div>
        <GridContainer>
          <Categories.Loop>
            <GridItem>
              <ArticleList />
            </GridItem>
          </Categories.Loop>
        </GridContainer>
        {/* Code to show subcategories and there artice listing. Maybe required in the future. */}
        {/* <GridContainer>
          {categories.map(category => (
            <Categories.Loop parentCategoryId={category && category.id}>
              <GridItem>
                <ArticleList />
              </GridItem>
            </Categories.Loop>
          ))}
        </GridContainer> */}
      </MainSection>
    </PageLayout>);
};
export default HomePage;
