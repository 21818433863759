import { getParameterByName } from "../utils/url-helper";
export const BHIdpLogout = (SID, clientId, ssoBaseUrl) => {
    let form = document.createElement('form');
    let redirectURL;
    if (getParameterByName('themeVersion')) {
        redirectURL = `${window.location.origin}/en?themeVersion=${getParameterByName('themeVersion')}&themeId=${getParameterByName('themeId')}`;
    }
    else {
        redirectURL = `${window.location.origin}/en`;
    }
    form.action = `${ssoBaseUrl}/logout?client_id=${clientId}&sid=${SID}&post_logout_redirect_uri=${redirectURL}`;
    form.method = "POST";
    document.body.appendChild(form);
    form.submit();
};
