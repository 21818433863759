import React, { useEffect } from "react";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ErrorPage from "../components/ErrorPage";
import { SID_KEY, USER_EMAIL_KEY, removeDataFromLocalStorage, removeTokenFromLocalStorage } from "../utils/local-storage";
import { ssoLogout } from "../requests/sso-logout";
import { BHIdpLogout } from "../requests/bh-idp-logout";
import { getSSOConfig } from "../requests/get-sso-config";
import { getParameterByName } from "../utils/url-helper";
const SSOLogout = () => {
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const SID = urlSearchParams.get('s_id');
        let redirectURL;
        if (getParameterByName('themeVersion')) {
            redirectURL = `${window.location.origin}/en?themeVersion=${getParameterByName('themeVersion')}&themeId=${getParameterByName('themeId')}`;
        }
        else {
            redirectURL = `${window.location.origin}/en`;
        }
        ssoLogout(SID).then(async (response) => {
            const config = await getSSOConfig();
            const data = await BHIdpLogout(SID, config === null || config === void 0 ? void 0 : config.clientAppId, config === null || config === void 0 ? void 0 : config.ssoBaseUrl);
            logout();
        }).catch(err => {
            console.log(err);
            window.location.href = redirectURL;
        });
    }, []);
    const logout = () => {
        removeTokenFromLocalStorage();
        removeDataFromLocalStorage(SID_KEY);
        removeDataFromLocalStorage(USER_EMAIL_KEY);
    };
    return (<PageLayout header={<Header />} footer={<Footer />}>
      <ErrorPage title={"Logged Out!"}>
        <p className="error-message">
            CRM SSO has been logged Out
        </p>
      </ErrorPage>
    </PageLayout>);
};
export default SSOLogout;
