const BASE_URL = process.env.COMCATE_API_URL;
const CRM_TOKEN = process.env.CRM_TOKEN;
export const getSubmissions = (token, contactInfo, returnMagicLink = false) => {
    const queryParams = new URLSearchParams({ token: CRM_TOKEN });
    let url = `${BASE_URL}/crm/${CRM_TOKEN}/request-user-submission?${queryParams}`;
    if (returnMagicLink) {
        url = url + '&returnMagicLink=true';
    }
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ contactInfo }),
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        }
        else {
            throw Error(response.statusText);
        }
    });
};
